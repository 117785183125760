import { FC } from "react";
import { Typography } from "@/components/shared/typography";
import { ButtonLink } from "@/components/elements/button-link";

import styles from "./index.module.scss";

export const NotFoundBanner: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography type="h1">Страница не найдена</Typography>
        <Typography className={styles.description} type="simpleText">
          К сожалению, мы не можем предложить страховку от устаревших ссылок и несуществующих страниц.
        </Typography>
        <Typography className={styles.description} type="simpleText">
          Но вы можете вернуться на главную страницу <br /> и ознакомиться с другими нашими услугами
        </Typography>
        <ButtonLink className={styles.button} href="/">
          На главную страницу
        </ButtonLink>
      </div>
    </div>
  );
};
