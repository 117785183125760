import { NotFoundBanner } from "@/components/shared/not-found-banner";
import { UsefulLinks, UsefulLinksData } from "@/components/sections/useful-links";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { MobileAppBottom, MobileAppBottomData } from "@/components/sections/mobile-app-bottom";
import { Image } from "@/components/sections/types";
import { useEffect, useState } from "react";
import { getGlobalData } from "@/utils/api/requests/main-site";
import { IGlobalContext } from "@/utils/globalContext/types";
import { PageContainer } from "@/components/layout";

const USEFUL_LINKS: UsefulLinksData = {
  title: { text: "Полезные ссылки" },
  links: [
    { text: "Калькулятор ОСАГО", url: "https://mafin.ru/osago/calc" },
    { text: "Калькулятор КАСКО", url: "https://mafin.ru/kasko/calc" },
    { text: "Страхование жизни и здоровья", url: "https://mafin.ru/strahovanie-ns" },
    { text: "Страховка для путешествий", url: "https://mafin.ru/strahovanie-vzr" },
    {
      text: "Оформить ОСАГО онлайн",
      url: "https://mafin.ru/osago",
    },
    { text: "Оформить КАСКО онлайн", url: "https://mafin.ru/kasko" },
    { text: "Страхование от несчастных случаев", url: "https://mafin.ru/strahovanie-ns/calc" },
    {
      text: "Туристическая страховка онлайн",
      url: "https://mafin.ru/strahovanie-vzr/calc",
    },
    { text: "Страхование недвижимости", url: "https://mafin.ru/strahovanie-imushchestva" },
    { text: "Страхование ипотеки онлайн", url: "https://mafin.ru/strahovanie-ipoteki" },
    {
      text: "Рассчитать страховку на квартиру",
      url: "https://mafin.ru/strahovanie-imushchestva/calc",
    },
    { text: "Рассчитать страховку по ипотеке", url: "https://mafin.ru/strahovanie-ipoteki/calc" },
  ],
  appearance: {
    paddingTop: true,
    paddingBottom: true,
    background: "White",
  },
};

const MOBILE_APP_BOTTOM: MobileAppBottomData = {
  title: "Мобильное приложение Mafin",
  subtitle: "Ваши полисы всегда под рукой",
  qr: { url: "https://mafin-cms-uploads.storage.yandexcloud.net/qr_5733e20900.svg" } as Image,
  background: { url: "https://mafin-cms-uploads.storage.yandexcloud.net/mobile_app_bg_4cf3222a93.png" } as Image,
  button: {
    url: "https://redirect.appmetrica.yandex.com/serve/243418001813016332",
    text: "Скачать",
    newTab: true,
    relNofollow: true,
  },
};

export default function Page404() {
  const [global, setGlobal] = useState<IGlobalContext | null>(null);

  useEffect(() => {
    getGlobalData().then((res) => setGlobal(res));
  }, []);

  return (
    <PageContainer global={global}>
      <NotFoundBanner />
      <UsefulLinks data={USEFUL_LINKS} />
      <MobileAppBottom data={MOBILE_APP_BOTTOM} />
    </PageContainer>
  );
}
